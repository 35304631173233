import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import { Grid, Row, Col } from 'react-flexbox-grid'

import findImage from '../../utils/findImage'
import Layout from '../../components/Layout'
import WorkTitleBlock from '../../components/WorkTitleBlock'
import FullPage from '../../components/FullPage'

export const frontmatter = {
  title: 'Wi-Fi Zone Poster',
  client: 'Gorod.dp.ua',
  type: 'Print',
  path: '/works/print/wi-fi/',
  date: '2007-07-21',
  image: 'wi-fi-demo',
  favorite: true,
}

export default ({ location }) => {
  const query = useStaticQuery(
    graphql`
      query {
        images: allFile(filter: { relativePath: { regex: "^/gorod/i/" } }) {
          edges {
            node {
              name
              childImageSharp {
                fluid(maxWidth: 1200, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    `
  )
  const images = query.images.edges

  return (
    <Layout location={location}>
      <Helmet title={frontmatter.title} />

      <FullPage align="top">
        <Grid fluid>
          <Row>
            <Col xs={12} sm={4}>
              <h1>{frontmatter.title}</h1>
              <p>
                This poster was made to support the{' '}
                <a href="http://gorod.dp.ua/restoran/catalog/?service_type=324">
                  {' '}
                  free Wi-Fi campaign
                </a>{' '}
                for Gorod.dp.ua.
              </p>
            </Col>
            <Col xs={12} sm={1}></Col>
            <Col xs={12} sm={5}>
              <figure className="text-center">
                <Image fluid={findImage(images, 'wi-fi')} alt="Wi-Fi Zone" />
                <figcaption>Inside is cozy and free WiFi</figcaption>
              </figure>
            </Col>
          </Row>
        </Grid>
      </FullPage>

      <WorkTitleBlock {...frontmatter} />
    </Layout>
  )
}
